/* eslint-disable prettier/prettier */
import * as TYPES from '@api/types';
import WALLETS, {DUMMY_WALLET} from '@config/wallets/wallets';
import {getNameOfBankForSBP} from '@pages/dashboard/Wallets/WalletsForm/utils';
import {IGetBotUsersDataFormatted} from "@api/types";
import {CUSTOM_USERS} from './config';

export const formatPursesInfo = (
    data: TYPES.IGetPaymentsInfoData[] | null,
): TYPES.IGetPaymentsInfoDataFormatted | null => {
    return Array.isArray(data)
        ? data.reduce((formattedPurses, purse) => {
            const purseInfo = purse.attributes.info.reduce(
                (formattedPurseInfo, item) => ({
                    ...formattedPurseInfo,
                    [item.currency]: {...item},
                }),
                {},
            );
            return {
                ...formattedPurses,
                [purse.id]: {...purse.attributes, info: purseInfo},
            };
        }, {})
        : data;
};

export const formatCryptoCurrencyPrice = (
    data: TYPES.IGetCryptoPriceData | null,
): TYPES.IGetCryptoPriceDataFormatted | null => {
    return data ? {id: data.id, ...data.attributes} : null;
};

export const formatManager = (
    data: TYPES.IGetManagerData | null,
): TYPES.IGetManagerDataFormatted | null =>
    data && {
        name: data.attributes?.fullname,
        contacts: data.attributes?.contacts,
    };

export const formatCoupons = (
    data: TYPES.IGetCouponsData[] | null,
): TYPES.IGetCouponsDataFormatted[] | null =>
    Array.isArray(data)
        ? data.map(({id, attributes}) => ({
            id,
            ...attributes,
            promocode: attributes.code,
            title: attributes.name,
        }))
        : data;

export const formatCouponsThemes = (
    data: TYPES.IGetCouponsThemesData[] | null,
): TYPES.IGetCouponsThemesDataFormatted[] | null =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : data;

export const formatCouponsTypes = (
    data: TYPES.IGetCouponsTypesData[] | null,
): TYPES.IGetCouponsTypesDataFormatted[] | null =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : data;

export const formatCouponsOffers = (data: TYPES.IGetCouponsOffersData[]): string =>
    Array.isArray(data) ? data.map(({id}) => id).join(',') : '';

export const formatCountries = (
    data: TYPES.IGetCountriesData[],
): TYPES.IGetCountriesDataFormatted[] =>
    Array.isArray(data)
        ? data.map(({attributes: {code, name, oksm}}) => ({
            value: code,
            label: name,
            oksm,
        }))
        : [];

export const formatCities = (data: TYPES.IGetCitiesData[]): TYPES.IGetCitiesDataFormatted[] => {
    if (!Array.isArray(data)) return [];

    const result = data.map(({attributes: {name}, id}) => {
        const cityId = typeof id === 'number' ? id.toString() : id;
        return {value: cityId, label: name};
    });

    return result;
};

export const formatSubs = (
    data: TYPES.IGetSubsData[] | null,
): TYPES.IGetSubsDataFormatted[] | null =>
    Array.isArray(data)
        ? data.map(({id, attributes}) => ({
            id,
            subs: {...attributes},
        }))
        : [];

export const formatCreatives = (
    data: TYPES.IGetCreativesData[] | null,
): TYPES.IGetCreativesDataFormatted[] =>
    Array.isArray(data)
        ? /**
         * TODO: разобраться зачем так сделано
         * В этом форматтере поле description становится полем name
         * Соответственно, если в запросе в квери параметрах отдать
         * fiels="description", то свойства description никогда не будет в объекте
         */
        data.map(({id, attributes: {description: name, ...other}}) => ({
            id: id.toString(),
            name,
            description: name,
            ...other,
        }))
        : [];

export const formatCreativeOffers = (data: TYPES.IGetCreativesOffersData[] | null): string[] =>
    Array.isArray(data) ? data.map(({id}) => id) : [];

export const formatCreativeBanners = (
    data: TYPES.IGetCreativesBannersData[] | null,
): TYPES.IGetCreativesBannersDataFormatted[] =>
    Array.isArray(data)
        ? data.map(({id, attributes: {cdn_url: src, link}}) => ({
            id,
            src,
            link,
        }))
        : [];

export const formatReduceLinks = (
    data: TYPES.IPostLinkReductionData | null,
): TYPES.IPostLinkReductionDataFormatted[] =>
    Array.isArray(data?.attributes)
        ? data.attributes.map(({id, result}) => ({id, link: result}))
        : [];

export const formatTrafficSources = (
    data: TYPES.IGetTrafficSourcesData[] | null,
): TYPES.IGetTrafficSourcesDataFormatted[] =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : [];

export const formatPlacementsOffers = (
    data: TYPES.IGetPlacementsOffersData[] | null,
): TYPES.IGetPlacementsOffersDataFormatted[] =>
    Array.isArray(data) ? data.map(({id}) => ({id})) : [];

export const formatCommonStatSummary = (
    data: TYPES.IGetCommonStatSummaryData,
): TYPES.IGetCommonStatSummaryDataAttributes | Record<string, void> =>
    data ? {...data.attributes} : {};

export const formatOffers = (
    data: TYPES.IGetOffersData[] | null,
): TYPES.IGetOffersDataFormatted[] => {
    const stringToBool = value => {
        if (typeof value !== 'string') return value;
        return value === 'true';
    };

    if (!Array.isArray(data)) return [];

    return data.map(({id, attributes}) => {
        const {
            logoSmall: logo,
            logo: logoBig,
            hasFeed,
            hasBonusProgram,
            isExclusive,
            creativePlacement: mustHavePlacement,
            ...other
        } = attributes || {};
        return {
            id,
            ...(logo && {logo}),
            ...(logoBig && {logoBig}),
            ...(hasFeed && {hasFeed: stringToBool(hasFeed)}),
            ...(hasBonusProgram && {hasBonusProgram: stringToBool(hasBonusProgram)}),
            ...(isExclusive && {isExclusive: stringToBool(isExclusive)}),
            ...(mustHavePlacement && {mustHavePlacement}),
            ...other,
        };
    });
};

export const formatOffersCompilations = (
    data: TYPES.IGetOffersCompilationsData[] | null,
): TYPES.IGetOffersCompilationsDataFormatted[] =>
    Array.isArray(data)
        ? data.map(({id, attributes: {name, offersIds}}) => ({
            id,
            name,
            offersIds,
        }))
        : [];

export const formatOfferDetail = (
    data: TYPES.IGetOfferDetailData | null,
): TYPES.IGetOfferDetailDataFormatted | Record<string, void> => {
    const {id, attributes = {}} = data || {};

    const {demands, ...other} = attributes;

    return {
        id,
        demands: demands && JSON.parse(demands)?.[0],
        ...other,
    };
};

export const formatStatOffers = (
    data: TYPES.IGetStatOffersData[] | null,
): TYPES.IGetStatOffersDataFormatted[] =>
    Array.isArray(data)
        ? data.map(({id, attributes: {name, currency, type_id: typeId}}) => ({
            ref: typeId === 2,
            id,
            name,
            currency,
        }))
        : [];

export const formatBotToken = (
    data: TYPES.IGetBotTokenData[] | null,
): TYPES.IGetBotTokenDataAttributes | Record<string, void> =>
    data?.[0] ? {...data[0].attributes} : null;

export const formatBotUsers = (
    data: TYPES.IGetBotUsersData[] | null,
): IGetBotUsersDataFormatted[] =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }: TYPES.IGetBotUsersData): IGetBotUsersDataFormatted => ({id, ...attributes})) : [];

export const formatNotifications = (
    data: TYPES.IGetNotificationsData | null,
): TYPES.IGetNotificationsDataFormatted[] =>
    Array.isArray(data?.attributes)
        ? data.attributes.map(item => ({...item, isRead: !!item?.read}))
        : [];

export const formatUserProfile = (
    data: TYPES.IGetUserProfileData | null,
): TYPES.IGetUserProfileDataFormatted | Record<string, void> => {
    const {attributes: userData = {}} = data || {};
    const {
        email,
        fullName = '',
        userName = '',
        profileImage = '',
        id: userId,
        inviteHash,
        isConfirmed: isConfirmedEmail,
        phoneConfirmed: phoneConfirmStatus,
        phone: phoneNumber,
        hasSignupPromocodeError,
        isPasswordSet,
        vkMiniApp,
        wmOnboarding,
        geo,
        subscriptions,
        socialNetworks,
        hasOrdData,
        selfSendingTokens,
        blockPaymentsOrd,
        postback,
        postbackFormat,
    } = userData as TYPES.IGetUserProfileDataAttributes;

    const {
        has_affiliate_link: hasAffiliateLink = true,
        has_allowed_placement: hasAllowedPlacement = true,
        has_placement: hasPlacement = true,
        hide_comfort_steps: hideComfortSteps = true,
    } = wmOnboarding || {};

    const [name] = fullName.split(' ');

    const formattedData = {
        email,
        name,
        fullName,
        profileImage,
        userName,
        userId,
        inviteHash,
        isConfirmedEmail: !!isConfirmedEmail,
        phoneConfirmStatus,
        phoneNumber,
        hasSignupPromocodeError: !!hasSignupPromocodeError,
        isPasswordSet,
        isCustomUser: !!CUSTOM_USERS[userId],
        allowedPurses: CUSTOM_USERS[userId],
        isVKMiniAppUser: !!vkMiniApp,
        wmOnboarding: {
            hasAffiliateLink,
            hasAllowedPlacement,
            hasPlacement,
            hideComfortSteps,
        },
        geo: {
            city_id:
                typeof geo.city_id === 'number' && geo.city_id !== 0
                    ? geo.city_id.toString()
                    : null,
            city_name: geo.city_name || null,
            country_code: geo.country_code || null,
            country_name: geo.country_name || null,
            region_code: geo.region_code || null,
            region_name: geo.region_name || null,
        },
        subscriptions,
        socialNetworks,
        hasOrdData,
        selfSendingTokens,
        blockPaymentsOrd,
        postback,
        postbackFormat,
    };

    return formattedData;
};

export const formatAlerts = (
    data: TYPES.IGetAlertsData[] | null,
): TYPES.IGetAlertsDataFormatted[] =>
    Array.isArray(data)
        ? data.map(({id, attributes}) => ({
            id,
            message: {
                en: attributes.message_en,
                ru: attributes.message,
            },
        }))
        : [];

export const formatPlacements = (
    data: TYPES.IGetPlacementsData[] | null,
): TYPES.IGetPlacementsDataFormatted[] =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : [];

export const formatUserPurses = (
    data: TYPES.IGetUserPursesData[] | null,
): TYPES.IGetUserPursesDataFormatted[] | null => {
    if (!Array.isArray(data)) return data;

    // TODO: Очень тяжелая функция, необходимо будет переписать.
    const result = data.map(item => {
        const localWallet = WALLETS.find(localItem => localItem.type === item.attributes.type);
        const apiWallet = {
            id: item.id,
            type: item.attributes?.type,
            purseType: item.attributes?.type,
            isDefault: item.attributes?.is_default,
            isConfirmed: item.attributes?.is_confirmed,
            isCharity: item.attributes?.isCharity,
            bankData:
                getNameOfBankForSBP((item.attributes?.purse as TYPES.IPurseValueSBP)?.bank_id) ||
                '',
            purseName: item.attributes?.purseName || '',
            purseValue:
                (item.attributes?.purse as TYPES.IPurseValueCapitalistCardUkr)?.account ||
                (item.attributes?.purse as TYPES.IPurseValueEpayments)?.number ||
                (item.attributes?.purse as TYPES.IPurseValueBank131)?.masked_card_number ||
                (item.attributes?.purse as TYPES.IPurseValueSBP)?.phone ||
                (item.attributes?.purse as string) ||
                '',
        };

        if (!localWallet)
            return {
                ...DUMMY_WALLET,
                ...apiWallet,
            };

        return {
            ...localWallet,
            ...apiWallet,
        };
    });

    return result;
};

export const formatSupportDialogs = (
    data: TYPES.IGetSupportDialogs | null,
): TYPES.ISupportDialogsDataAttributes[] => {
    if (!data || !Array.isArray(data.attributes)) return [];

    return data.attributes;
};

export const formatSupportMessageCount = (
    data: TYPES.ISupportDialogCountByStatus | null,
): TYPES.ISupportDialogCountByStatusAttributes => {
    if (!data || !data.attributes)
        return {
            all: 0,
            open: 0,
            closed: 0,
        };

    return data.attributes;
};

export const formatSupportTheme = (
    data: TYPES.ISupportThemes | null,
): TYPES.ISupportThemesAttributes[] => {
    if (!data || !Array.isArray(data.attributes)) return [];

    return data.attributes;
};

export const formatSupportDialogMessages = (
    data: TYPES.ISupportDialogMessages | null,
): TYPES.ISupportDialogMessagesAttributes => {
    if (!data || !Array.isArray(data.attributes.messages))
        return {
            messages: [],
            ticket: null,
        };

    return {
        messages: data.attributes.messages.reverse(),
        ticket: data.attributes.ticket,
    };
};

export const formatSupportQuestionSuggests = (
    data: TYPES.IGetSupportQuestionSuggests | null,
): TYPES.ISupportQuestionSuggestsDataAttributesFormatted[] => {
    if (!data || !Array.isArray(data.attributes)) return [];

    return data.attributes.map((item: TYPES.ISupportQuestionSuggestsDataAttributes) => ({
        title: item.question,
        body: item.answer,
        key: item.id,
    }));
};

export const formatFeeds = (data: TYPES.IGetFeedsData[] | null): TYPES.IGetFeedsDataFormatted[] =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : [];

export const formatFeed = (data: TYPES.IGetFeedData | null): TYPES.IGetFeedsDataFormatted | null =>
    data ? {id: data.id, ...data.attributes} : null;

export const formatDumps = (data: TYPES.IGetDumpsData[] | null): TYPES.IGetDumpsDataFormatted[] =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : [];

export const formatDump = (data: TYPES.IGetDumpData | null): TYPES.IGetDumpDataFormatted | null =>
    data ? {id: data.id, ...data.attributes} : null;

export const formatCategories = (
    data: TYPES.IGetCategoriesData[] | null,
): TYPES.IGetCategoriesDataFormatted[] =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : [];

export const formatCreativeDeeplinks = (
    data: TYPES.IGetDeeplinksData[] | null,
): TYPES.IGetDeeplinksDataFormatted[] =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : [];

export const formatPlacement = (
    data: TYPES.IGetPlacementData | null,
): TYPES.IGetPlacementDataFormatted | null => (data ? {id: data.id, ...data.attributes} : null);

export const formatStories = (
    data: TYPES.IGetStoriesData[] | null,
): TYPES.IGetStoriesDataFormated[] | null =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : [];
export const formatDomains = (data: TYPES.IGetDomainsData[]): TYPES.IGetDomainsDataFormatted[] =>
    Array.isArray(data) ? data.map(({
                                        id,
                                        attributes
                                    }) => ({id, ...attributes})) : [];
export const formatMotivations = (data: TYPES.IGetMotivation): TYPES.IGetMotivationData =>
    data.attributes;
